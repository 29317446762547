import React, { useEffect, useMemo, useRef } from "react";
import { useSelector } from "react-redux";

import { ReactComponent as ComfortImg } from "../../assets/imgs/whyUs/comfort.svg";
import { ReactComponent as FlexibilityImg } from "../../assets/imgs/whyUs/flexibility.svg";
import { ReactComponent as SecurityImg } from "../../assets/imgs/whyUs/shield.svg";

import { RootState } from "../../store/store";

import { scrollToNode } from "../../utils/ts/helperFunctions";

import "./WhyUs.scss";

const WhyUs: React.FC = () => {
  const whyUsSection = useRef<HTMLElement>(null!);
  const isMount = useRef(false);

  const scrollToWhyUs = useSelector((state: RootState) => state.scrollState.scrollToWhyUs);
  const currentLanguage = useSelector(
    (state: RootState) => state.languageState.currentLanguage.name
  );

  const cardsData = useMemo(() => {
    return (currentLanguage: string) => [
      {
        title:
          currentLanguage === "ua" ? "комфорт" : currentLanguage === "ru" ? "комфорт" : "comfort",
        img: <ComfortImg />,
        desc:
          currentLanguage === "ua"
            ? "З нами у вас є можливість забрати гроші в будь-якому обласному центрі України або ж отримати їх кур'єром по Києву"
            : currentLanguage === "ru"
            ? "С нами у вас есть возможность забрать деньги в любом областном центре Украины или же получить их курьером по Киеву"
            : "With us you have the opportunity to pick up money in any regional center of Ukraine or receive it by courier in Kiev"
      },
      {
        title:
          currentLanguage === "ua"
            ? "гнучкість"
            : currentLanguage === "ru"
            ? "гибкость"
            : "flexibility",
        img: <FlexibilityImg />,
        desc:
          currentLanguage === "ua"
            ? "Наш досвід і знання, а також персональний підхід дають нам можливість запропонувати найбільш вигідні умови кожному клієнту"
            : currentLanguage === "ru"
            ? "Наш опыт и знания, а также персональный подход дают нам возможность предложить наиболее выгодные условия каждому клиенту"
            : "Our experience and knowledge, as well as a personal approach, enable us to offer the most favorable conditions to each client"
      },
      {
        title:
          currentLanguage === "ua"
            ? "безпека"
            : currentLanguage === "ru"
            ? "безопасность"
            : "security",
        img: <SecurityImg />,
        desc:
          currentLanguage === "ua"
            ? "Успішне проведення операції - наше пріоритетне завдання. Отже, будь-яку угоду можна провести при особистій зустрічі в нашому офісі"
            : currentLanguage === "ru"
            ? "Успешное проведение операции - наша приоритетная задача. Следовательно, любую сделку можно провести при личной встрече в нашем офисе"
            : "Successful operation is our top priority. Therefore, any transaction can be carried out in person in our office"
      }
    ];
  }, []);

  useEffect(() => {
    if (isMount.current && whyUsSection.current) {
      scrollToNode(whyUsSection.current);
    }
    isMount.current = true;
  }, [scrollToWhyUs]);

  return (
    <section className="why-us" ref={whyUsSection}>
      <div className="why-us__inner">
        <h5 className="why-us__heading">
          {currentLanguage === "ua"
            ? "Отримайте максимум від своїх активів"
            : currentLanguage === "ru"
            ? "Получите максимум от своих активов"
            : "Get the most out of your assets"}
        </h5>
        <p className="why-us__subheading">
          {currentLanguage === "ua"
            ? "Наша компанія є трейдером з бездоганною репутацією. Ми надаємо прозорі умови і завжди знаходимо компроміс з клієнтом для досягнення загального успіху."
            : currentLanguage === "ru"
            ? "Наша компания является трейдером с безупречной репутацией. Мы предоставляем прозрачные условия и всегда находим компромисс с клиентом для достижения общего успеха."
            : "Our company is a trader with an impeccable reputation. We provide transparent conditions and always find a compromise with the client to achieve overall success."}
        </p>
        <p className="why-us__subheading">
          {currentLanguage === "ua"
            ? "Ось лише кілька причин, чому ви повинні вибрати нашу компанію"
            : currentLanguage === "ru"
            ? "Вот лишь несколько причин, почему вы должны выбрать нашу компанию"
            : "Here are just a few reasons why you should choose our company"}
        </p>
        <div className="why-us__cards">
          {cardsData(currentLanguage).map((card) => (
            <div key={card.title} className="why-us__card">
              <h6 className="why-us__card-title">{card.title}</h6>
              <div className="why-us__card-img">{card.img}</div>
              <p className="why-us__card-desc">{card.desc}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default WhyUs;
