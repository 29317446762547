import { createStore, combineReducers } from "redux";

import cryptoCurrencyReducer from "./reducers/cryptoCurrencyReducer";
import mobileMenuReducer from "./reducers/mobileMenuReducer";
import scrollReducer from "./reducers/scrollReducer";
import languageReducer from "./reducers/languageReducer";

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION__: any;
  }
}

const rootReducer = combineReducers({
  cryptoCurrenciesState: cryptoCurrencyReducer,
  mobileMenuState: mobileMenuReducer,
  scrollState: scrollReducer,
  languageState: languageReducer
});

export type RootState = ReturnType<typeof rootReducer>;

const store = createStore(
  rootReducer,
  process.env.NODE_ENV === "development"
    ? window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    : undefined
);

export default store;
