import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import Spinner from "../Spinner/Spinner";
import Modal from "../Modal/Modal";
import { ReactComponent as SWWImg } from "../../assets/errorImgs/client-server-error.svg";
import CryptoCalculator from "../CryptoCalculator/CryptoCalculator";

import { scrollToNode } from "../../utils/ts/helperFunctions";

import ImgBTC from "../../assets/currenciesLogo/BTC.png";
import ImgETH from "../../assets/currenciesLogo/ETH.png";
import ImgUSDT from "../../assets/currenciesLogo/USDT.png";
import ImgXRP from "../../assets/currenciesLogo/XRP.png";
import ImgLTC from "../../assets/currenciesLogo/LTC.png";
import ImgXMR from "../../assets/currenciesLogo/XMR.png";
import ImgTRX from "../../assets/currenciesLogo/TRX.png";
import ImgDASH from "../../assets/currenciesLogo/DASH.png";

import flagUSA from "../../assets/flags/usa-flag.png";
import flagUA from "../../assets/flags/ua-flag.png";
import flagEU from "../../assets/flags/eu-flag.png";

import { Currency } from "../../models/currency";

import { RootState } from "../../store/store";
import * as cryptoCurrencyActions from "../../store/actions/cryptoCurrencyActions/cryptoCurrencyActionCreators";

import "./Calculator.scss";

const Calculator: React.FC = () => {
  const dispatch = useDispatch();

  const сalcSection = useRef<HTMLElement>(null!);

  const scrollToCalc = useSelector((state: RootState) => state.scrollState.scrollToCalc);
  const currentLanguage = useSelector(
    (state: RootState) => state.languageState.currentLanguage.name
  );

  const [isFetchingBinanceData, setIsFetchingBinanceData] = useState(true);
  const [isFetchingOwnData, setIsFetchingOwnData] = useState(true);
  const [isSomethingWentWrong, setIsSomethingWentWrong] = useState(false);

  const fetchBinanceData = useCallback(async () => {
    try {
      const response = await fetch("https://apiv2.bitcoinaverage.com/exchanges/ticker/binance", {
        headers: {
          "x-ba-key": "MjY4ZmJkNGFiNzJkNDJjNzg4NjkwNDE5NzE2NDMxZGU"
        }
      });
      if (response.status !== 200) {
        return setIsSomethingWentWrong(true);
      }
      let resData: {
        symbols: {
          BTCUSDT: { bid: number };
          ETHUSDT: { bid: number };
          XRPUSDT: { bid: number };
          LTCUSDT: { bid: number };
          XMRUSDT: { bid: number };
          TRXUSDT: { bid: number };
          DASHUSDT: { bid: number };
        };
      } = await response.json();
      console.log(resData);
      const newCurrenciesFromCustomer: Currency[] = [
        {
          name: "BTC",
          valueBuy: resData.symbols.BTCUSDT.bid,
          valueSale: resData.symbols.BTCUSDT.bid,
          img: ImgBTC
        },
        {
          name: "ETH",
          valueSale: resData.symbols.ETHUSDT.bid,
          valueBuy: resData.symbols.ETHUSDT.bid,
          img: ImgETH
        },
        {
          name: "USDT",
          valueSale: 1,
          valueBuy: 1,
          img: ImgUSDT
        },
        {
          name: "XRP",
          valueSale: resData.symbols.XRPUSDT.bid,
          valueBuy: resData.symbols.XRPUSDT.bid,
          img: ImgXRP
        },
        {
          name: "LTC",
          valueSale: resData.symbols.LTCUSDT.bid,
          valueBuy: resData.symbols.LTCUSDT.bid,
          img: ImgLTC
        },
        {
          name: "XMR",
          valueSale: resData.symbols.XMRUSDT.bid,
          valueBuy: resData.symbols.XMRUSDT.bid,
          img: ImgXMR
        },
        {
          name: "TRX",
          valueSale: resData.symbols.TRXUSDT.bid,
          valueBuy: resData.symbols.TRXUSDT.bid,
          img: ImgTRX
        },
        {
          name: "DASH",
          valueSale: resData.symbols.DASHUSDT.bid,
          valueBuy: resData.symbols.DASHUSDT.bid,
          img: ImgDASH
        }
      ];
      dispatch(cryptoCurrencyActions.setCurrenciesFromCustomer(newCurrenciesFromCustomer));
      dispatch(
        cryptoCurrencyActions.setCurrentCurrencyFromCustomer({
          name: "BTC",
          img: ImgBTC,
          valueSale: resData.symbols.BTCUSDT.bid,
          valueBuy: resData.symbols.BTCUSDT.bid
        })
      );
      setIsFetchingBinanceData(false);
    } catch (error) {
      return setIsSomethingWentWrong(true);
    }
  }, [dispatch]);

  const fetchOwnData = useCallback(async () => {
    try {
      const response = await fetch(
        `https://exchange-currencies-obolon.firebaseio.com/currencies.json`
      );
      if (response.status !== 200) {
        return setIsSomethingWentWrong(true);
      }
      let resData: {
        cryptoPercentages: {};
        rates: {
          opt: {
            usd: { buy: number; sell: number };
            eur: { buy: number; sell: number };
          };
        };
      } = await response.json();
      console.log(resData);
      const newCryptoCurrenciesToCustomer: Currency[] = [
        {
          name: "USD",
          valueSale: 1,
          valueBuy: 1,
          img: flagUSA
        },
        {
          name: "EUR",
          valueSale: resData.rates.opt.eur.sell / resData.rates.opt.usd.buy,
          valueBuy: resData.rates.opt.eur.buy / resData.rates.opt.usd.sell,
          img: flagEU
        },
        {
          name: "UAH",
          valueSale: 1 / resData.rates.opt.usd.buy,
          valueBuy: 1 / resData.rates.opt.usd.sell,
          img: flagUA
        }
      ];

      dispatch(cryptoCurrencyActions.setCurrenciesToCustomer(newCryptoCurrenciesToCustomer));
      dispatch(
        cryptoCurrencyActions.setCurrentCurrencyToCustomer({
          name: "USD",
          img: flagUSA,
          valueSale: 1,
          valueBuy: 1
        })
      );
      dispatch(cryptoCurrencyActions.setPercentages(Object.values(resData.cryptoPercentages)));
      setIsFetchingOwnData(false);
    } catch (error) {
      return setIsSomethingWentWrong(true);
    }
  }, [dispatch]);

  const closeSWWModal = useCallback(() => {
    setIsSomethingWentWrong(false);
  }, []);

  useEffect(() => {
    fetchBinanceData();
    fetchOwnData();
  }, [fetchBinanceData, fetchOwnData]);

  useEffect(() => {
    if (сalcSection.current) {
      scrollToNode(сalcSection.current);
    }
  }, [scrollToCalc]);

  if (isFetchingBinanceData || isFetchingOwnData) {
    return <Spinner />;
  }

  return (
    <>
      {/* {(isFetchingBinanceData || isFetchingOwnData) && <Spinner />} */}
      {isSomethingWentWrong && (
        <Modal
          Img={SWWImg}
          closeModal={closeSWWModal}
          msg={
            currentLanguage === "ua"
              ? "щось пішло не так. спробуйте ще раз"
              : currentLanguage === "ru"
              ? "что-то пошло не так. попробуйте еще раз"
              : "something went wrong. try again"
          }
        />
      )}
      <section className="calculator-section" ref={сalcSection}>
        <div className="calculator-section__inner">
          <div className="calculator-section__calculator">
            <CryptoCalculator />
          </div>
        </div>
      </section>
    </>
  );
};

export default Calculator;
