import * as languageActionTypes from "../actions/languageActions/languageActionTypes";
import { Language } from "../../models/language";

import uaFlag from "../../assets/flags/ua-flag.png";

interface LanguageState {
  currentLanguage: Language;
}

const initialState: LanguageState = {
  currentLanguage: {
    name: "ua",
    img: uaFlag
  }
};

const store = (
  state = initialState,
  action: languageActionTypes.LanguageActionType
): LanguageState => {
  switch (action.type) {
    case languageActionTypes.SELECT_LANGUAGE:
      localStorage.setItem("lang", action.payload.language.name);
      return { ...state, currentLanguage: action.payload.language };

    default:
      return state;
  }
};

export default store;
