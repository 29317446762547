import React, { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import smoothscroll from "smoothscroll-polyfill";

import Header from "./components/Header/Header";
import MenuMobile from "./components/MenuMobile/MenuMobile";
import Hero from "./components/Hero/Hero";
import WhyUs from "./components/WhyUs/WhyUs";
import Calculator from "./components/Calculator/Calculator";
import Exchange from "./components/Exchange/Exchange";
import Contacts from "./components/Contacts/Contacts";
import Spinner from "./components/Spinner/Spinner";

import { languages } from "./utils/ts/languageItems";

import * as languageActions from "./store/actions/languageActions/languageActionCreators";

import "./App.scss";

const App: React.FC = () => {
  const dispatch = useDispatch();

  const [isCheckingLang, setIsCheckingLang] = useState(true);

  const checkLang = useCallback(() => {
    const languageName = localStorage.getItem("lang");
    if (!languageName) {
      setIsCheckingLang(false);
      return;
    }
    const selectedLanguage = languages.find((lang) => lang.name === languageName)!;
    dispatch(languageActions.selectLanguage(selectedLanguage));

    setIsCheckingLang(false);
  }, [dispatch]);

  useEffect(() => {
    smoothscroll.polyfill();
  }, []);

  useEffect(() => {
    checkLang();
  }, [checkLang]);

  if (isCheckingLang) {
    return <Spinner />;
  }

  return (
    <div className="app">
      <Header />
      <MenuMobile />
      <Hero />
      <Calculator />
      <Exchange />
      <WhyUs />
      <Contacts />
    </div>
  );
};

export default App;
