import { Dispatch } from "redux";

import { ScrollActionType } from "./../../store/actions/scrollActions/scrollActionTypes";
import * as scrollActions from "../../store/actions/scrollActions/scrollActionCreators";
import * as mobileMenuActions from "../../store/actions/mobileMenuActions/mobileMenuActionCreators";

const onClick = (action: ScrollActionType, dispatch: Dispatch) => {
  dispatch(mobileMenuActions.closeMobileMenu());
  dispatch(action);
};

const navItems = (dispatch: Dispatch, language: string) => [
  {
    title: language === "ua" ? "калькулятор" : language === "ru" ? "калькулятор" : "calculator",
    onClick: () => {
      onClick(scrollActions.scrollToCalc(), dispatch);
    }
  },
  {
    title:
      language === "ua"
        ? "створити заявку"
        : language === "ru"
        ? "создать заявку"
        : "create a request",
    onClick: () => {
      onClick(scrollActions.scrollToExchange(), dispatch);
    }
  },
  {
    title: language === "ua" ? "чому ми" : language === "ru" ? "почему мы" : "why us",
    onClick: () => {
      onClick(scrollActions.scrollToWhyUs(), dispatch);
    }
  },
  {
    title: language === "ua" ? "контакти" : language === "ru" ? "контакты" : "contacts",
    onClick: () => {
      onClick(scrollActions.scrollToContacts(), dispatch);
    }
  }
];

export default navItems;
