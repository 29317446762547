import React, { useRef, useState, useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import Spinner from "../Spinner/Spinner";
import Modal from "../Modal/Modal";
import { ReactComponent as SWWImg } from "../../assets/errorImgs/client-server-error.svg";
import { ReactComponent as SuccessImg } from "../../assets/success/success.svg";

import { RootState } from "../../store/store";
import * as scrollActions from "../../store/actions/scrollActions/scrollActionCreators";

import { scrollToNode } from "../../utils/ts/helperFunctions";

import "./Exchange.scss";

const Exchange: React.FC = () => {
  const dispatch = useDispatch();

  const exchangeSection = useRef<HTMLElement>(null!);
  const isMount = useRef(false);

  const scrollToExchange = useSelector((state: RootState) => state.scrollState.scrollToExchange);
  const currentLanguage = useSelector(
    (state: RootState) => state.languageState.currentLanguage.name
  );

  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [comment, setComment] = useState("");
  const [isNameValid, setIsNameValid] = useState(true);
  const [isNumberValid, setIsNumberValid] = useState(true);

  const [isSomethingWentWrong, setIsSomethingWentWrong] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSendingDataSuccess, setIsSendingDataSuccess] = useState(false);

  const scrollToContacts = useCallback(() => {
    dispatch(scrollActions.scrollToContacts());
  }, [dispatch]);

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
      if (e.target.name === "name") {
        setName(e.target.value);
      }
      if (e.target.name === "number") {
        setNumber(e.target.value);
      }
      if (e.target.name === "comment") {
        setComment(e.target.value);
      }
    },
    []
  );

  const onFocusInput = useCallback((e: React.FocusEvent<HTMLInputElement>) => {
    if (e.target.name === "name") {
      setIsNameValid(true);
    } else {
      setIsNumberValid(true);
    }
  }, []);

  const sendData = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      setIsLoading(true);
      let isFormValid = true;
      if (name.length === 0) {
        setIsNameValid(false);
        isFormValid = false;
      }
      if (number.length < 10) {
        setIsNumberValid(false);
        isFormValid = false;
      }
      if (!isFormValid) {
        return setIsLoading(false);
      }

      try {
        // const response = await fetch(
        //   `https://api.telegram.org/bot957327615:AAH0MITHU3soRisXfcZZmnHSgI9RqoqBTdo/sendMessage?chat_id=174294535&parse_mode=html&text=${encodeURI(
        //     `<u><b>4K GROUP</b></u>\n<i>имя:</i> <b>${name}</b>\n<i>номер:</i> <u><b>${number}</b></u>${
        //       comment.length > 0 ? `\n<i>комментарий:</i> <b>${comment}</b>` : ``
        //     }`
        //   )}`
        const response = await fetch(
          `https://api.telegram.org/bot1612391615:AAF2SX2f9xTW0Bj87YY7WtSh3ar3e-Bx_8E/sendMessage?chat_id=448641137&parse_mode=html&text=${encodeURI(
            `<u><b>Crypto Obmin</b></u>\n<i>имя:</i> <b>${name}</b>\n<i>номер:</i> <u><b>${number}</b></u>${
              comment.length > 0 ? `\n<i>комментарий:</i> <b>${comment}</b>` : ``
            }`
          )}`
        );
        if (response.status !== 200) {
          setIsLoading(false);
          return setIsSomethingWentWrong(true);
        }
        setName("");
        setNumber("");
        setComment("");
        setIsLoading(false);
        setIsSendingDataSuccess(true);
      } catch (error) {
        setIsLoading(false);
        return setIsSomethingWentWrong(true);
      }
    },
    [name, number, comment]
  );

  const closeSWWModal = useCallback(() => {
    setIsSomethingWentWrong(false);
  }, []);

  const closeSuccessModal = useCallback(() => {
    setIsSendingDataSuccess(false);
  }, []);

  useEffect(() => {
    if (isMount.current && exchangeSection.current) {
      scrollToNode(exchangeSection.current);
    }
    isMount.current = true;
  }, [scrollToExchange]);

  return (
    <>
      {isLoading && <Spinner />}
      {isSomethingWentWrong && (
        <Modal
          Img={SWWImg}
          closeModal={closeSWWModal}
          msg={
            currentLanguage === "ua"
              ? "щось пішло не так. спробуйте ще раз"
              : currentLanguage === "ru"
              ? "что-то пошло не так. попробуйте еще раз"
              : "something went wrong. try again"
          }
        />
      )}
      {isSendingDataSuccess && (
        <Modal
          Img={SuccessImg}
          closeModal={closeSuccessModal}
          msg={
            currentLanguage === "ua"
              ? "дані відправлені"
              : currentLanguage === "ru"
              ? "данные отправлены"
              : "data has been sent"
          }
        />
      )}
      <section className="exchange" ref={exchangeSection}>
        <div className="exchange__inner">
          <div className="exchange__desc">
            {currentLanguage === "ua"
              ? "Для здійснення обміну залиште заявку і ми вам передзвонимо. Або зв'яжіться з нами по даним з"
              : currentLanguage === "ru"
              ? "Для совершения обмена оставьте заявку и мы вам перезвоним. Или свяжитесь с нами по данным из"
              : "To make an exchange, leave a request and we will call you back. Or contact us using the data from the"}
            &nbsp;
            <span className="exchange__contact-link" onClick={scrollToContacts}>
              {currentLanguage === "ua"
                ? "контактів"
                : currentLanguage === "ru"
                ? "контактов"
                : "contacts"}
            </span>
          </div>
          <form className="exchange__form" onSubmit={sendData}>
            <div className="exchange__required">
              * -&nbsp;
              {currentLanguage === "ua"
                ? "обов'язкові поля"
                : currentLanguage === "ru"
                ? "обязательные поля"
                : "required fields"}
            </div>
            <div className="exchange__inputs">
              <div className="exchange__form-group">
                <input
                  type="text"
                  className={"exchange__input" + (isNameValid ? "" : " exchange__input_invalid")}
                  placeholder={
                    (currentLanguage === "ua"
                      ? "ім'я"
                      : currentLanguage === "ru"
                      ? "имя"
                      : "name") + "*"
                  }
                  autoComplete="off"
                  name="name"
                  value={name}
                  onChange={onChange}
                  onFocus={onFocusInput}
                />
                {isNameValid ? null : (
                  <small>
                    {currentLanguage === "ua"
                      ? "треба якось себе назвати"
                      : currentLanguage === "ru"
                      ? "надо как-то себя назвать"
                      : "you have to name yourself somehow"}
                  </small>
                )}
              </div>
              <div className="exchange__form-group">
                <input
                  type="tel"
                  className={"exchange__input" + (isNumberValid ? "" : " exchange__input_invalid")}
                  placeholder={
                    (currentLanguage === "ua"
                      ? "телефон"
                      : currentLanguage === "ru"
                      ? "телефон"
                      : "phone") + "*"
                  }
                  autoComplete="off"
                  name="number"
                  value={number}
                  onChange={onChange}
                  onFocus={onFocusInput}
                />
                {isNumberValid ? null : (
                  <small>
                    {currentLanguage === "ua"
                      ? "мінімум 10 знаків"
                      : currentLanguage === "ru"
                      ? "минимум 10 знаков"
                      : "at least 10 characters"}
                  </small>
                )}
              </div>
            </div>
            <div className="exchange__comment">
              <textarea
                placeholder={
                  (currentLanguage === "ua"
                    ? "коментар"
                    : currentLanguage === "ru"
                    ? "комментарий"
                    : "сomment") + "*"
                }
                name="comment"
                rows={3}
                value={comment}
                onChange={onChange}
              />
            </div>
            <button type="submit" className="exchange__btn">
              {currentLanguage === "ua"
                ? "відправити"
                : currentLanguage === "ru"
                ? "отправить"
                : "submit"}
            </button>
          </form>
          <p className="exchange__error">
            {currentLanguage === "ua"
              ? "(якщо ми вам не телефонуємо, то, ймовірно, ви помилилися при вказанні номера. спробуйте ще раз)"
              : currentLanguage === "ru"
              ? "(если мы вам не перезваниваем, то, вероятно, вы ошиблись при указании номера. попробуйте еще раз)"
              : "(if we do not call you back, then, probably, you made a mistake when specifying the number. try again)"}
          </p>
        </div>
      </section>
    </>
  );
};

export default Exchange;
