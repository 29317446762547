import { Language } from "../../../models/language";

export const SELECT_LANGUAGE = "SELECT_LANGUAGE";

export interface SelectLanguage {
  type: typeof SELECT_LANGUAGE;
  payload: {
    language: Language;
  };
}

export type LanguageActionType = SelectLanguage;
