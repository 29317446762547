import React, { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import { ReactComponent as Logo } from "../../assets/logo/logo_full.svg";

import * as scrollActions from "../../store/actions/scrollActions/scrollActionCreators";
import { RootState } from "../../store/store";

import "./Hero.scss";

const Hero: React.FC = () => {
  const dispatch = useDispatch();

  const currentLanguage = useSelector(
    (state: RootState) => state.languageState.currentLanguage.name
  );

  const scrollToExchange = useCallback(() => {
    dispatch(scrollActions.scrollToExchange());
  }, [dispatch]);

  return (
    <section className="hero">
      <div className="hero__inner">
        <div className="hero__text">
          <div className="hero__logo">
            <Logo />
          </div>
          <p>
            {currentLanguage === "ua"
              ? "ваш надійний партнер в обмінних операціях"
              : currentLanguage === "ru"
              ? "ваш надежный партнер в обменных операциях"
              : "your reliable partner in exchange transactions"}
          </p>
        </div>
      </div>
      <button className="hero__btn" onClick={scrollToExchange}>
        {currentLanguage === "ua"
          ? "оформити заявку"
          : currentLanguage === "ru"
          ? "оформить заявку"
          : "arrange a request"}
      </button>
    </section>
  );
};

export default Hero;
