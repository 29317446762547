import React, { useState, useEffect, useCallback, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import { RootState } from "../../store/store";
import { languages } from "../../utils/ts/languageItems";

import * as languageActions from "../../store/actions/languageActions/languageActionCreators";

import "./LanguageSelector.scss";

const LanguageSelector: React.FC = () => {
  const dispatch = useDispatch();

  const currentLang = useSelector((state: RootState) => state.languageState.currentLanguage);

  const selectedElem = useRef<HTMLDivElement>(null!);
  const optionsListElem = useRef<HTMLDivElement>(null!);

  const [isOpenedOptions, setIsOpenedOptions] = useState(false);

  const toggleIsOpenedOptions = useCallback(() => {
    const elem = optionsListElem.current;
    if (isOpenedOptions) {
      elem.style.borderWidth = "0";
      elem.style.marginTop = "";
      elem.style.height = "0";
    } else {
      const borderWidth = 1;
      elem.style.borderWidth = `${borderWidth}px`;
      elem.style.marginTop = "0.5rem";
      elem.style.height = elem.scrollHeight + borderWidth * 2 + "px";
    }

    setIsOpenedOptions((prevState) => !prevState);
  }, [isOpenedOptions]);

  const closeCurrenciesOptions = useCallback(() => {
    const elem = optionsListElem.current;
    elem.style.borderWidth = "0";
    elem.style.height = "0";
    elem.style.marginTop = "";
    setIsOpenedOptions(false);
  }, []);

  const closeOpenedOptions = useCallback(
    (e: MouseEvent) => {
      let element = e.target as HTMLElement;
      while (element !== document.body) {
        if (element === selectedElem.current) {
          return;
        }
        const parentElement = element.parentElement;
        if (parentElement) {
          element = parentElement;
        } else {
          return;
        }
      }
      if (isOpenedOptions) {
        closeCurrenciesOptions();
      }
    },
    [closeCurrenciesOptions, isOpenedOptions]
  );

  const onChangeLanguage = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      const languageName = e.currentTarget.getAttribute("data-name")!;
      const newSelectedLanguage = languages.find((lang) => lang.name === languageName)!;
      dispatch(languageActions.selectLanguage(newSelectedLanguage));
    },
    [dispatch]
  );

  useEffect(() => {
    window.addEventListener("click", closeOpenedOptions);
    return () => {
      window.removeEventListener("click", closeOpenedOptions);
    };
  }, [closeOpenedOptions]);

  return (
    <div className="language-selector">
      <div
        className="language-selector__selected"
        onClick={toggleIsOpenedOptions}
        ref={selectedElem}
      >
        <img
          className="language-selector__img"
          src={currentLang.img}
          alt={`${currentLang.name}-flag`}
        />
      </div>
      <div className="language-selector__select-options" ref={optionsListElem}>
        {languages.map((item) => (
          <div
            className="language-selector__select-options-item"
            key={item.name}
            data-name={item.name}
            onClick={onChangeLanguage}
          >
            <img
              className="language-selector__currency-img"
              src={item.img}
              alt={item.name + "-logo"}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default LanguageSelector;
